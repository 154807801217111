<template>
    <div>
        <div class="cash-pricing__content">
            <cash-pricing-filter
                ref="filter"
                :ws-base-url="wsBaseUrl"
                :translations="translations"
                @commodities="onCommoditiesLoaded"
                @grades="onGradeSelected"
                @group="onGroup"
                @prices="onPricesChanged"
                @sites="onSiteSelected" />
        </div>
        <div v-if="(!sites.length || !grades.length) && !pricesLoaded" class="cash-pricing__result-error-outer">
            <div class="cash-pricing__result-error">
                <div class="cash-pricing__result-error-inner">
                    {{ translations.noSelect }}
                </div>
            </div>
        </div>
        <!-- layouts -->
        <template v-else-if="pricesLoaded">
            <template v-if="groups.length">
                <!-- layout: group by site -->
                <div v-if="groupBy === GroupOption.SITE" class="cash-pricing__content">
                    <div v-for="group in groups" :key="`${group.id}-info`">
                        <h5 :key="`${group.id}-title`" class="cash-pricing__site-heading">
                            {{ group.title }}
                            <button class="cash-pricing__deselect-group-btn" @click="removeGroup(group.id)">
                                <icon class="icon" name="icn-cross" />
                            </button>
                        </h5>
                        <template v-if="group.commodities.length">
                            <segregation-cash-table
                                v-for="commodity in group.commodities"
                                :key="`${group.id}-${commodity.title}`"
                                :group="commodity"
                                :translations="translations"
                                @remove="removeGroup">
                                <div class="cash-pricing__no-price-site-error">
                                    {{ translations.noPriceSite }}
                                </div>
                            </segregation-cash-table>
                        </template>
                    </div>
                </div>
                <!-- layout: group by commodity -->
                <div v-else-if="groupBy === GroupOption.COMMODITY" class="cash-pricing__content">
                    <vit-tabs mobile sliding :tabs-lenght="groups.length">
                        <vit-tab
                            v-for="group in groups"
                            :key="`table-${group.commodity.cId}`"
                            :title="group.commodity.cName"
                            fixed-width
                            capitalize
                            narrow>
                            <segregation-cash-table
                                v-for="subgroup in group.grades"
                                :key="`table-${subgroup.id}`"
                                :group="subgroup"
                                :translations="translations"
                                @remove="removeGroup">
                                <div :key="`${subgroup.id}-noprice`" class="cash-pricing__no-price-grade-error">
                                    {{ translations.noPriceGrade }}
                                </div>
                            </segregation-cash-table>
                        </vit-tab>
                    </vit-tabs>
                </div>
                <!-- layout: no grouping -->
                <div v-else-if="groupBy === GroupOption.NONE" class="cash-pricing__sticky-table">
                    <table class="table cash-pricing__table cash-pricing__table-ungrouped">
                        <thead>
                            <tr>
                                <th v-for="th in SortOptionNoGrouping" :key="th" @click="sortTable(th)">
                                    {{ translations[th] }}
                                    <icon
                                        :class="`cash-pricing__sort-arrow cash-pricing__sort-arrow-${getSortDir(th)}`"
                                        name="icn-sort-arrow" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="price in groups" :key="price.id">
                                <td>{{ price.site.sName }}</td>
                                <td>{{ price.commodity.cName }}</td>
                                <td>{{ price.grade.gName }}</td>
                                <td>{{ price.owner.oDescription }}</td>
                                <td v-if="price.sellInfo.isPool">
                                    {{ price.price }}
                                </td>
                                <td v-else>
                                    ${{ price.price.toFixed(2) }}
                                </td>
                                <td>{{ price.sellInfo.sOptionDescription }}</td>
                                <td>{{ price.sellInfo.pTermDescription }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <div v-else class="cash-pricing__result-error-outer">
                <div class="cash-pricing__result-error">
                    <div class="cash-pricing__result-error-inner">
                        {{ translations.noResults }}
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="cash-pricing__result-error">
            <loading-spinner />
        </div>
    </div>
</template>

<script lang="ts">
// here we have the order and display of values
// the values are fetched at filters
import { defineComponent } from 'vue';
import { SortDir, GroupOption, PriceInfo, SiteInfo, CommodityInfo, GradeInfo, GradePoolInfo, SortOption, SortOptionNoGrouping } from '../../lib/Segregation';
import CashPricingFilter from './CashPricingFilter.vue';
import SegregationCashTable from './SegregationCashTable.vue';
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import Icon from '../atoms/Icon.vue';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, SegregationCashTable, CashPricingFilter, LoadingSpinner, Icon },
    props: {
        translations: { required: true, type: Object as () => Record<string, string> },
        wsBaseUrl: { required: true, type: String }
    },
    data() {
        return {
            GroupOption,
            SortOption,
            SortOptionNoGrouping,

            sites: [] as SiteInfo[],
            grades: [] as GradeInfo[],
            prices: [] as PriceInfo[],
            commodities: [] as Array<{ [key: string]: any }>,
            groupBy: GroupOption.SITE,
            sortBy: SortOption.SITE,
            sortDir: SortDir.DEFAULT,

            // aux variables
            pricesLoaded: false,
            refreshGroup: 0
        };
    },

    computed: {
        // display order
        groups(): {
            id: string, title: string, commodities:
            { title: string, gradePools: GradePoolInfo[], type: 'site' | 'commodity' }[]
        }[] // site grouping
            | { commodity: CommodityInfo, grades: { id: string, title: string, gradePools: GradePoolInfo[], type: 'site' | 'commodity' }[] }[] // commodity grouping
            | PriceInfo[] { // no grouping
            // eslint-disable-next-line no-unused-expressions
            this.refreshGroup;
            switch (this.groupBy) {
                case GroupOption.SITE:
                    return this.sites.map((site: SiteInfo) => ({
                        id: site.sId,
                        title: site.sName,
                        commodities: this.pricesBySite(site)
                    }))
                        .filter(x => x.commodities.length)
                        .sort((a, b) => (a.title < b.title ? -1 : 1));
                case GroupOption.COMMODITY:
                    return Object.entries(this.grades.map((grade: GradeInfo) => ({
                        // map grades into groups by grade
                        id: grade.gId,
                        title: grade.gName,
                        type: 'commodity',
                        gradePools: this.pricesByGrade(grade)
                    }))
                        .filter(x => x.gradePools.length)
                        .reduce((res, x) => {
                            // group grades into commodities
                            const commodity = this.commodities.find(c => c.grades.some(g => g.gId === x.id));
                            if (!commodity) {
                                // no commodity found for grade
                                return res;
                            }
                            if (!Object.prototype.hasOwnProperty.call(res, commodity.cId)) {
                                res[commodity.cId] = [];
                            }
                            res[commodity.cId].push(x);
                            return res;
                        }, {})).map(x => ({ commodity: this.commodities.find(c => c.cId === x[0]), grades: x[1] }))
                        .sort((a, b) =>
                            this.commodities.findIndex(x => x.id === a.commodity.id) - this.commodities.findIndex(x => x.id === b.commodity.id)) as any;
                default:
                    return this.sortedPrices;
            }
        },

        // group no group - sort by prices
        sortedPrices(): PriceInfo[] {
            return this.prices.map(p => p).sort((a: PriceInfo, b: PriceInfo) => {
                let c: number;
                switch (this.sortBy) {
                    case SortOption.SITE:
                        c = this.sortBySiteName(a, b, this.sortDir !== SortDir.ASC ? SortDir.ASC : SortDir.DESC);
                        if (c === 0) { c = this.sortByCommodity(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        if (c === 0) { c = this.sortByPrice(a, b, SortDir.DESC); }
                        break;
                    case SortOption.COMMODITY:
                        c = this.sortByCommodity(a, b, this.sortDir !== SortDir.ASC ? SortDir.ASC : SortDir.DESC);
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByPrice(a, b, SortDir.DESC); }
                        break;
                    case SortOption.GRADE:
                        c = this.sortByCommodity(a, b, SortDir.ASC);
                        if (c === 0) { c = this.sortByGrade(a, b, this.sortDir); }
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByPrice(a, b, SortDir.DESC); }
                        break;
                    case SortOption.BUYER:
                        c = this.sortByBuyer(a, b, this.sortDir);
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByCommodity(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        if (c === 0) { c = this.sortByPrice(a, b, SortDir.DESC); }
                        break;
                    case SortOption.PRICE:
                        c = this.sortByPrice(a, b, this.sortDir);
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByCommodity(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        break;
                    case SortOption.PRICE_TYPE:
                        c = this.sortByPriceType(a, b, this.sortDir);
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByCommodity(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        break;
                    case SortOption.PAYMENT_TERMS:
                        c = this.sortByPaymentTerms(a, b, this.sortDir);
                        if (c === 0) { c = this.sortBySiteName(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByCommodity(a, b, SortDir.ASC); }
                        if (c === 0) { c = this.sortByGrade(a, b, SortDir.DESC); }
                        break;
                    default:
                        c = 0;
                }
                return c;
            });
        }
    },

    methods: {
        /*
        * sort
        */
        sortBySiteName(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.site.sName === b.site.sName) {
                return 0;
            }
            const s: number = a.site.sName < b.site.sName ? -1 : 1;
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByPrice(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.price === b.price) {
                return 0;
            }
            const s: number = (a.price as number) - (b.price as number);
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByCommodity(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.commodity.cName === b.commodity.cName) {
                return 0;
            }
            const s: number = a.commodity.cName.toLowerCase() === 'wheat'
                ? -1
                : b.commodity.cName.toLowerCase() === 'wheat'
                    ? 1
                    : (a.commodity.cName < b.commodity.cName ? -1 : 0);
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByGrade(b: PriceInfo, a: PriceInfo, sortDir: SortDir): number {
            if (a.gRank === b.gRank) {
                return 0;
            }
            // Higher grades have lower numbers
            const s: number = a.gRank > b.gRank ? 1 : -1;
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByPriceType(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.sellInfo.sOptionDescription === b.sellInfo.sOptionDescription) {
                return 0;
            }

            const s = a.sellInfo.sOptionDescription < b.sellInfo.sOptionDescription ? -1 : 1;
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByPaymentTerms(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.sellInfo.pTermDescription === b.sellInfo.pTermDescription) {
                return 0;
            }

            const s = a.sellInfo.pTermDescription < b.sellInfo.pTermDescription ? -1 : 1;
            return sortDir === SortDir.ASC ? s : -s;
        },
        sortByBuyer(a: PriceInfo, b: PriceInfo, sortDir: SortDir): number {
            if (a.owner.oDescription === b.owner.oDescription) {
                return 0;
            }
            const s: number = a.owner.oDescription < b.owner.oDescription ? -1 : 1;
            return sortDir === SortDir.ASC ? s : -s;
        },

        // group by site - order commodities in site
        pricesBySite(site: SiteInfo): { title: string, gradePools: GradePoolInfo[], type: 'site' | 'commodity' }[] {
            // all grade pools
            const gradePools = Object.values(this.prices
                .filter(price => price.site.sId === site.sId)
                .reduce((res, p) => {
                    if (!Object.prototype.hasOwnProperty.call(res, p.grade.gName)) {
                        res[p.grade.gName] = { grade: p.grade, prices: [] };
                    }
                    res[p.grade.gName].prices.push(p);
                    return res;
                }, {})).map(({ grade, prices }) =>
                ({
                    id: `${site.sId}-${grade.gId}`,
                    title: grade.gName,
                    prices,
                    max: prices.reduce((max, p) => (p.price !== 'POA' && p.price > max ? p.price : max), 0).toFixed(2)
                }));
            // group by commodity
            const commodities = gradePools.reduce((res, gp: GradePoolInfo) => {
                const commodity = this.commodities.find(c => c.grades.some(g => g.gName === gp.title));
                if (!Object.prototype.hasOwnProperty.call(res, commodity.cId)) {
                    res[commodity.cId] = [];
                }
                res[commodity.cId].push(gp);
                return res;
            }, {});
            return Object.entries<GradePoolInfo[]>(commodities).map(x => ({
                title: this.commodities.find(c => c.cId === x[0]).cName || '',
                gradePools: x[1].sort((a, b) => this.grades.findIndex(g => g.gName === a.title) - this.grades.findIndex(y => y.gName === b.title)),
                type: 'site'
            })).sort((a, b) => // sort wheat first
                (a.title.toLowerCase() === 'wheat' ? -1 : b.title.toLowerCase() === 'wheat' ? 1 : (a.title < b.title ? -1 : 0))) as any[];
        },

        // group by commodity - order grades
        pricesByGrade(grade: GradeInfo): GradePoolInfo[] {
            return Object.values(this.prices
                .filter(price => price.grade.gId === grade.gId)
                .reduce((res, p) => {
                    if (!Object.prototype.hasOwnProperty.call(res, p.site.sName)) {
                        res[p.site.sName] = { site: p.site, prices: [] };
                    }
                    res[p.site.sName].prices.push(p);
                    return res;
                }, {})).map(({ site, prices }) =>
                ({
                    id: `${grade.gId}-${site.sId}`,
                    title: site.sName,
                    prices,
                    max: prices.reduce((max, p) => (p.price !== 'POA' && p.price > max ? p.price : max), 0).toFixed(2)
                }));
        },

        // add filter
        removeGroup(groupId) {
            if (this.groupBy === GroupOption.SITE) {
                this.$refs.filter.selectSite(groupId);
            }
            if (this.groupBy === GroupOption.COMMODITY) {
                this.$refs.filter.selectGrade(groupId);
            }
        },

        // group by no group - sort by column
        sortTable(option: SortOption) {
            if (this.sortBy !== option) {
                // a different sort column was selected
                this.sortBy = option;
                this.sortDir = SortDir.DESC; // first time sort on new column
            } else {
                // the same sort column was clicked again
                switch (this.sortDir) {
                    case SortDir.ASC:
                        this.sortDir = SortDir.DEFAULT;
                        break;
                    case SortDir.DESC:
                        this.sortDir = SortDir.ASC;
                        break;
                    case SortDir.DEFAULT:
                        this.sortDir = SortDir.DESC;
                        break;
                    default:
                        this.sortDir = SortDir.DEFAULT;
                }
            }
            // ask vue component filter to fetch again
            this.$refs.filter.fetchPrices();
        },

        // group by no group - sort dir
        getSortDir(col: string): string {
            if (this.sortBy !== col || this.sortDir === SortDir.DEFAULT) {
                return 'default';
            }
            return this.sortDir === SortDir.DESC ? 'desc' : 'asc';
        },

        /**
         * Events from filter
         */
        onSiteSelected(sites: SiteInfo[]) {
            this.sites = sites;
            this.pricesLoaded = true;
        },

        onGradeSelected(grades: GradeInfo[]) {
            this.grades = grades;
        },

        onGroup(option: GroupOption) {
            this.groupBy = option;
        },

        onPricesChanged(prices: PriceInfo[]) {
            this.pricesLoaded = true;
            this.prices = prices;
            // force groups to update
            this.refreshGroup++;
        },

        onCommoditiesLoaded(commodities: CommodityInfo[]) {
            this.commodities = commodities;
        }
    }
});

</script>
