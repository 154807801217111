<template>
    <div class="tabbed-slide" v-show="active">
        <div class="tabbed-slide__image-container">
            <div class="tabbed-slide__image" ref="imageContainer">
                <slot />
            </div>
            <div class="tabbed-slide__overlay-filler" />
            <img
                class="tabbed-slide__img"
                :src="determineOverlayImagePath()"
                @load="svgLoaded"
                :alt="title">
            <div class="tabbed-slide__overlay-filler" />
        </div>
        <div class="tabbed-slide__title-container">
            <div v-if="isEditMode" class="w-100" cms:edit="bar" />
            <div class="tabbed-slide__title">
                {{ title }}
            </div>
            <a :href="link" class="button tabbed-slide__button" :target="linkTarget">{{ linkLabel }}</a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { debounce } from 'lodash';

export default defineComponent({
    props: {
        title: String,
        text: String,
        textShort: String,
        image: String,
        link: String,
        linkLabel: String,
        linkTarget: String,
        isEditMode: Boolean,
        mask: String
    },
    data() {
        return {
            active: true,
            imageEl: null,
            onResize: null
        };
    },
    inject: ['childTabbedRegistration'],

    mounted() {
        this.onResize = debounce(this.scaleImage, 350);
        // register self on parent component
        this.childTabbedRegistration(this);
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        svgLoaded(event) {
            this.imageEl = event.target;
            if (this.active) {
                this.scaleImage();
            }
        },

        scaleImage() {
            if (this.imageEl && this.$refs.imageContainer) {
                const width = Math.floor((1035 * this.imageEl.clientHeight) / 720);
                this.imageEl.style.width = `${width}px`;
                this.imageEl.style.flexBasis = `${width}px`;
            }
        },
        determineOverlayImagePath(): string {
            return `${this.$resourcePath}img/hero-mask_${this.$theme}-${this.mask}.svg`;
        }
    },

    watch: {
        active() {
            if (this.imageEl && this.active) {
                this.$nextTick(() => {
                    this.scaleImage();
                });
            }
        }
    }
});
</script>
