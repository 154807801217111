<template>
    <div class="quality-stats">
        <div v-if="error">
            {{ errorMessage }}
        </div>
        <template v-else>
            <div>{{ dateLabel }}: {{ new Date().toString().substring(0, 15) }}</div>
            <div class="quality-stats__season-menu">
                <span class="quality-stats__season-label">{{ seasonLabel }}:</span>
                <vit-dropdown :label="currentSeasonLabel" inline light>
                    <dropdown-item
                        v-for="s in seasonData"
                        :key="s.seasonId"
                        :label="s.description"
                        @click="changeSeason(s.season)"
                        :selected="season === s.description" />
                </vit-dropdown>
            </div>
            <vit-tabs>
                <vit-tab v-for="(tab, t) in commodities" :key="`qt#${t}`" :title="tab.label">
                    <div class="mt-3 button" @click="exportXlsx(tab)">
                        {{ exportLabel }}
                    </div>
                    <table class="table table-responsive-lg">
                        <colgroup>
                            <col span="1">
                            <col span="1" style="width: 25%;">
                            <col span="1">
                            <col span="1">
                            <col span="1">
                            <col span="1">
                        </colgroup>
                        <thead>
                            <tr>
                                <th @click="sortColumn(t, 'grade')" class="quality-stats__column text-nowrap">
                                    {{ gradeLabel }}
                                    <icon
                                        name="icn-sort-arrow"
                                        :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, 'grade')}`" />
                                </th>
                                <th @click="sortColumn(t, 'site')" class="quality-stats__column">
                                    {{ siteLabel }}
                                    <icon
                                        name="icn-sort-arrow"
                                        :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, 'site')}`" />
                                </th>
                                <th
                                    v-for="column in tab.columns"
                                    :key="`th-${column.key}`"
                                    class="text-right quality-stats__column"
                                    @click="sortColumn(t, column.key)">
                                    <span class="text-nowrap">{{ column.label }}</span><br>
                                    <span class="text-nowrap">
                                        <icon
                                            name="icn-sort-arrow"
                                            :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, column.key)}`" />
                                        {{ column.unit }}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in tab.dataSorted" :key="`${data.grade}-${data.site}`">
                                <td>{{ data.grade }}</td>
                                <td>{{ data.siteDescription }}</td>
                                <td
                                    v-for="column in tab.columns"
                                    :key="`${column.key}-${data.grade}-${data.site}`"
                                    class="text-right">
                                    {{ data.qualities.find(x => x.testType === column.key).result.toFixed(2) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </vit-tab>
            </vit-tabs>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import axios from 'axios';
import { utils, writeFile } from 'xlsx';
import Icon from '../atoms/Icon.vue';
import DropdownItem from '../base/VitDropdownItem.vue';
import VitDropdown from '../base/VitDropdown.vue';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

interface CommodityHeaders {
    MO?: string;
    TW?: string;
    PR?: string;
    SC?: string;
    OA?: string;
    IM?: string;
}

interface QCommodity {
    dataSorted: any[];
    sortCol: string;
    data: any[];
    columns: { key: string; label: string; unit?: string }[];
    sortDesc: boolean;
    label: string;
}

export default defineComponent({
    components: { VitTab, VitTabs, VitDropdown, Icon, DropdownItem },
    props: {
        barleyUrl: { required: true, type: String },
        wheatUrl: { required: true, type: String },
        canolaUrl: { required: true, type: String },
        // seasons URL to test https://mobilewsstaging.viterra.com.au/api/Season
        seasonsUrl: { required: true, type: String },
        barleyLabel: { required: true, type: String },
        wheatLabel: { required: true, type: String },
        canolaLabel: { required: true, type: String },
        gradeLabel: { required: true, type: String },
        siteLabel: { required: true, type: String },
        errorMessage: { required: true, type: String },
        dateLabel: { required: true, type: String },
        seasonLabel: { required: true, type: String },
        exportLabel: { required: true, type: String },
        barleyHeader: { required: true, type: Object as PropType<CommodityHeaders> },
        wheatHeader: { required: true, type: Object as PropType<CommodityHeaders> },
        canolaHeader: { required: true, type: Object as PropType<CommodityHeaders> }
    },
    data() {
        return {
            seasonData: [],
            season: null,
            commodities: null as {
                barley: QCommodity,
                wheat: QCommodity,
                canola: QCommodity
            },
            error: false
        };
    },

    created() {
        this.commodities = {
            barley: {
                label: this.barleyLabel,
                columns: Object.entries(this.barleyHeader).map(x => ({
                    key: x[0],
                    label: (x[1] as string).substring(0, (x[1] as string).indexOf('(')).trim(),
                    unit: (x[1] as string).substr((x[1] as string).indexOf('(')).trim()
                })),
                data: [],
                dataSorted: [],
                sortCol: 'default',
                sortDesc: false
            },
            wheat: {
                label: this.wheatLabel,
                columns: Object.entries(this.wheatHeader).map(x => ({ key: x[0], label: x[1] })),
                data: [],
                dataSorted: [],
                sortCol: 'default',
                sortDesc: false
            },
            canola: {
                label: this.canolaLabel,
                columns: Object.entries(this.canolaHeader).map(x => ({ key: x[0], label: x[1] })),
                data: [],
                dataSorted: [],
                sortCol: 'default',
                sortDesc: false
            }
        };
    },

    async mounted() {
        await this.getSeasonData();
        await this.getData();
    },

    methods: {
        async getSeasonData() {
            try {
                const response = await axios.get(this.seasonsUrl);
                this.seasonData = response.data;
                this.season = this.seasonData.length > 0 ? this.seasonData[this.seasonData.length - 1].season : '22/23';
            } catch (e) {
                throw new Error(e.response ? e.response.message : e.message);
            }
        },

        async getData() {
            try {
                const { data: barleyData } = await axios.get(`${this.barleyUrl}?${this.query}`);
                this.commodities.barley.data = barleyData;
                this.commodities.barley.dataSorted = [...this.commodities.barley.data];
                const { data: wheatData } = await axios.get(`${this.wheatUrl}?${this.query}`);
                this.commodities.wheat.data = wheatData;
                this.commodities.wheat.dataSorted = [...this.commodities.wheat.data];
                const { data: canolaData } = await axios.get(`${this.canolaUrl}?${this.query}`);
                this.commodities.canola.data = canolaData;
                this.commodities.canola.dataSorted = [...this.commodities.canola.data];
            } catch (e) {
                this.error = true;
                console.error(e.response ? e.response.message : e.message);
            }
        },

        // update the sort column and direction. returns false if the sort order has been reverted to default, true otherwise
        setSortCol(commodity, col) {
            if (this.commodities[commodity].sortCol === col) {
                this.commodities[commodity].sortDesc = !this.commodities[commodity].sortDesc;
                if (!this.commodities[commodity].sortDesc) {
                    this.sortDefault(commodity);
                    return false;
                }
            } else {
                this.commodities[commodity].sortCol = col;
                this.commodities[commodity].sortDesc = false;
            }
            return true;
        },

        // revert to default order
        sortDefault(commodity) {
            this.commodities[commodity].sortCol = 'default';
            this.commodities[commodity].dataSorted = [...this.commodities[commodity].data];
        },

        // sort function for first to columns (no nesting)
        sortGradeSite(commodity, col) {
            const sortBefore = this.commodities[commodity].sortDesc ? 1 : -1;
            const sortAfter = 0 - sortBefore;
            this.commodities[commodity].dataSorted.sort((a, b) => (a[col] < b[col] ? sortBefore : sortAfter));
        },

        // sort function for values
        sortColumn(commodity, column) {
            if (!this.setSortCol(commodity, column)) {
                return;
            }
            // first 2 columns have a different sort function
            if (column === 'grade' || column === 'site') {
                this.sortGradeSite(commodity, column);
                return;
            }
            const sortBefore = this.commodities[commodity].sortDesc ? 1 : -1;
            const sortAfter = 0 - sortBefore;

            this.commodities[commodity].dataSorted.sort((a, b) => {
                const c = a.qualities.find(x => x.testType === column);
                const d = b.qualities.find(x => x.testType === column);
                if (c && d) {
                    return c.result < d.result ? sortBefore : sortAfter;
                }
                if (c && !d) {
                    return sortBefore;
                }
                if (!c && d) {
                    return sortAfter;
                }
                return 0;
            });
            this.$forceUpdate();
        },

        getSortDir(tab, col) {
            if (tab.sortCol !== col || tab.sortCol === 'default') {
                return 'default';
            }
            return tab.sortDesc ? 'desc' : 'asc';
        },

        exportXlsx(tab) {
            const exportData = [
                [this.gradeLabel, this.siteLabel, ...tab.columns.map(x => x.label)].map(s => ({
                    v: s,
                    t: 's',
                    s: {
                        fill: {
                            patternType: 'solid',
                            bgColor: { rgb: 'aaaaaa' }
                        }
                    }
                }))
            ];
            tab.data.forEach(data => {
                const row = [data.grade, data.siteDescription];
                tab.columns.forEach(col => {
                    row.push(data.qualities.find(x => x.testType === col.key).result);
                });
                exportData.push(row);
            });
            /* convert from array of arrays to workbook */
            const worksheet = utils.aoa_to_sheet(exportData);
            worksheet['!cols'] = new Array(tab.columns.length + 2).fill({ width: 25 });
            const wb = utils.book_new();
            utils.book_append_sheet(wb, worksheet, 'Sheet');
            /* generate file and send to client */
            writeFile(wb, `Viterra_QualityStats_${tab.label}.xlsx`);
        },

        async changeSeason(season) {
            this.season = season;
            await this.getData();
            this.$forceUpdate();
        }
    },

    computed: {
        query() {
            return Object.entries({
                $filter: `Season eq '${this.season}' `,
                $orderby: 'SiteDescription,Grade'
            }).map(x => `${x[0]}=${encodeURI(x[1])}`).join('&');
        },

        currentSeasonLabel() {
            const season = this.seasonData.find(x => x.season === this.season);
            return season ? season.description : '2022/23';
        }
    }
});

</script>
