<template>
    <div
        v-if="onShow"
        class="dropdown b-dropdown"
        :class="classes"
        @click="toggleDropdown">
        <div class="btn dropdown-toggle btn-secondary btn-block dropdown__button">
            <div class="dropdown__button-text">
                {{ label }}
            </div>
        </div>
        <collapse-transition>
            <ul class="dropdown-menu dropdown__menu show" v-if="isOpen" @click.stop>
                <slot />
            </ul>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
// review at maps with vit-dropdown-bar
// no-flip ? @hide="onHide" ? :disabled="disabled" ? :right="insideBar"
export default defineComponent({
    components: { CollapseTransition },
    props: {
        light: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        mobileOnly: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false },
        darkMenu: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        label: { type: String, default: '' }
    },
    data() {
        return {
            isOpen: false,
            items: [],
            itemClicked: false,
            preventCloseDropdown: false
        };
    },
    // inject need default, because vit-dropdown-bar can be not present
    inject: {
        registerDropdown: { default: () => {} },
        insideBar: { default: () => {} }
    },
    created() {
        provide('registerItem', this.registerItem);
        provide('unregisterItem', this.unregisterItem);
    },
    // register self in dropdown bar
    mounted() {
        if (this.registerDropdown) {
            this.registerDropdown(this);
        }
    },

    methods: {
        // called by child items
        registerItem(item: Object) {
            this.items.push(item);
        },
        unregisterItem(item: Object) {
            const index = this.items.indexOf(item);
            if (index !== -1) {
                this.items.splice(index, 1);
            }
        },

        toggleDropdown() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
                if (this.isOpen) {
                    document.addEventListener('click', this.handleClickOutside);
                } else {
                    document.removeEventListener('click', this.handleClickOutside);
                }
            }
        },

        // dont show empty dropdown menu
        onShow(event) {
            if (this.items.length === 0) {
                event.preventDefault();
            }
        },

        // prevent auto close when multiple or preventCloseDropdown is true
        onHide(event) {
            if ((this.multiple && this.itemClicked) || this.preventCloseDropdown) {
                event.preventDefault();
            }
            this.itemClicked = false;
            this.preventCloseDropdown = false;
        },
        // close if click outside
        handleClickOutside(e) {
            if (!this.$el.contains(e.target) && this.isOpen) {
                this.isOpen = false;
            }
        }
    },
    computed: {
        classes() {
            return {
                'dropdown--light': this.light,
                'dropdown--bar': this.insideBar,
                'dropdown--mobile': this.mobileOnly,
                'dropdown--inline': this.inline,
                'dropdown--rounded': this.rounded,
                'dropdown--dark-menu': this.darkMenu,
                'dropdown--multiple': this.multiple,
                'dropdown--disabled': this.disabled,
                show: this.isOpen
            };
        }
    }
});

</script>
