<template>
    <div class="cookie-popup" v-if="show">
        <div class="cookie-popup__container">
            <div class="cookie-popup__inner">
                <div class="cookie-popup__logo" :style="logoStyle" />
                <h3 class="cookie-popup__title">
                    {{ title }}
                </h3>
                <div v-html="text" />
                <slot />

                <button class="cookie-popup__settings-toggle" @click="toogleSettings">
                    <icon :name="settingsVisible ? 'icn-upload' : 'icn-download'" class="cookie-popup__settings-toggle-icon" />
                    {{ settingsLabel }}
                </button>
                <collapse-transition>
                    <div v-if="settingsVisible">
                        <h4 class="cookie-popup__headline">
                            <span class="cookie-popup__checkbox cookie-popup__checkbox--active" />
                            {{ technicalLabel }}
                        </h4>
                        <div v-html="technicalText" />
                        <h4 class="cookie-popup__headline">
                            <span
                                class="cookie-popup__checkbox"
                                :class="{ 'cookie-popup__checkbox--active': analyticsActive }"
                                @click="analyticsClicked" />
                            {{ analyticsLabel }}
                        </h4>
                        <div v-html="analyticsText" />
                        <h4 class="cookie-popup__headline">
                            <span
                                class="cookie-popup__checkbox"
                                :class="{ 'cookie-popup__checkbox--active': marketingActive }"
                                @click="marketingClicked" />
                            {{ marketingLabel }}
                        </h4>
                        <div v-html="marketingText" />
                    </div>
                </collapse-transition>
                <div class="cookie-popup__footer">
                    <button class="button" @click="save">
                        {{ saveLabel }}
                    </button>
                    <button class="button" @click="acceptAll">
                        {{ agreeAllLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable dot-notation */
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import Icon from './atoms/Icon.vue';
import Helper from '../lib/Helper';
import eventBus from '../lib/event';

const Cookie = {
    TECHNICAL: { cookie: 'technical', event: 'security_storage' },
    ANALYTICS: { cookie: 'analytics', event: 'analytics_storage' },
    MARKETING: { cookie: 'marketing', event: 'ad_storage' }
};

export default defineComponent({
    components: { Icon, CollapseTransition },
    props: {
        title: String,
        text: String,
        logo: String,
        settingsLabel: String,
        technicalLabel: String,
        technicalText: String,
        analyticsLabel: String,
        analyticsText: String,
        marketingLabel: String,
        marketingText: String,
        policyLink: String,
        saveLabel: String,
        agreeAllLabel: String
    },
    data() {
        return {
            show: false,
            settingsVisible: false,
            analyticsActive: false,
            marketingActive: false
        };
    },

    created() {
        this.show = this.checkCookie();
        this.initConsent();
    },

    mounted() {
        this.blockOverflow();
        eventBus.on('open-popup', this.onOpen);
    },

    beforeDestroy() {
        document.body.classList.remove('no-scroll');
        eventBus.off('open-popup', this.onOpen);
    },

    methods: {
        // actions when clicked
        analyticsClicked() {
            this.analyticsActive = !this.analyticsActive;
        },

        marketingClicked() {
            this.marketingActive = !this.marketingActive;
        },

        onOpen(id) {
            if (id === 'cookie') {
                this.show = true;
            }
        },

        acceptAll() {
            this.analyticsActive = true;
            this.marketingActive = true;
            this.updateConsent();
            this.save();
        },

        save() {
            this.updateConsent();
            const cookie = [
                { value: Cookie.TECHNICAL, enabled: true }, // technical is always set
                { value: Cookie.ANALYTICS, enabled: this.analyticsActive },
                { value: Cookie.MARKETING, enabled: this.marketingActive }
            ];
            const cookieValue = cookie.filter(x => x.enabled).map(x => x.value.cookie).join(',');
            Helper.setCookie('privacy-level', cookieValue);

            const dataLayer: any[] = (window as any).dataLayer;
            if (dataLayer) {
                cookie.forEach(c => {
                    dataLayer.push({ event: c.value.event });
                });
            }
            this.show = false;
            document.body.classList.remove('no-scroll');
            location.reload();
        },

        checkCookie(): boolean {
            const cookie = Helper.getCookie('privacy-level');
            if (cookie) {
                const urlParams = new URLSearchParams(window.location.search);
                const values = cookie.split(',');
                this.analyticsActive = values.includes(Cookie.ANALYTICS.cookie);
                this.marketingActive = values.includes(Cookie.MARKETING.cookie);
                const privacyPopup = urlParams.get('cookiePopup');
                if (!privacyPopup) return false;
            }
            return location.pathname !== this.policyLink;
        },

        initConsent(): void {
            this.gtag('consent', 'default', this.consent);
            this.gtag({
                event: 'cookie_consent_update'
            });
        },

        updateConsent(): void {
            this.gtag('consent', 'update', this.consent);
            this.gtag({
                event: 'cookie_consent_update'
            });
        },

        // eslint-disable-next-line no-unused-vars
        gtag(...args): void {
            // somehow it won't work when using the args parameter instead of arguments, so please don't change
            const dataLayer: any[] = (window as any).dataLayer;
            if (dataLayer) {
                /* eslint-disable-next-line prefer-rest-params */
                dataLayer.push(arguments);
            }
        },
        // open dropdown
        toogleSettings() {
            this.settingsVisible = !this.settingsVisible;
        },
        blockOverflow() {
            this.show ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
        }
    },

    computed: {
        logoStyle() {
            const styles = {};
            if (this.logo) {
                styles['background-image'] = `url(${this.logo})`;
            }
            return styles;
        },
        consent(): Record<string, string> {
            /* eslint-disable */
            return {
                [Cookie.TECHNICAL.event]: 'granted',
                [Cookie.ANALYTICS.event]: this.analyticsActive ? 'granted' : 'denied',
                [Cookie.MARKETING.event]: this.marketingActive ? 'granted' : 'denied'
            };
            /* eslint-enable */
        }
    },
    watch: {
        show() {
            this.blockOverflow();
        }
    }
});

</script>
