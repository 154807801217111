<template>
    <div>
        <form
            class="js-cm-form"
            :id="formId"
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            :data-id="dataId">
            <fieldset class="form__set">
                <div class="form__row">
                    <label for="fieldEmail" class="form__label form__required">
                        <span>Email</span>
                    </label>
                    <input
                        v-model="formData.email"
                        type="email"
                        required
                        autocomplete="Email"
                        aria-label="Email"
                        class="form__input js-cm-email-input qa-input-email"
                        id="fieldEmail"
                        maxlength="200"
                        :name="emailName">
                </div>
                <div class="form__row">
                    <label for="fieldtjhtitl" class="form__label form__required">
                        <span>First name</span>
                    </label>
                    <input
                        v-model="formData.firstName"
                        type="text"
                        required
                        autocomplete="off"
                        class="form__input"
                        aria-label="First name"
                        id="fieldtjhtitl"
                        maxlength="200"
                        :name="firstName">
                </div>
                <div class="form__row">
                    <label for="fieldtjhtiju" class="form__label form__required">
                        <span>Second name</span>
                    </label>
                    <input
                        v-model="formData.secondName"
                        type="text"
                        autocomplete="off"
                        class="form__input"
                        aria-label="Last name"
                        id="fieldtjhtiju"
                        maxlength="200"
                        :name="lastName">
                </div>
            </fieldset>
            <re-captcha-submit
                :is-filled="allData"
                :site-key="captchaKey"
                :site-name="siteName"
                :label="label" />
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ReCaptchaSubmit from './ReCaptchaSubmit.vue';

export default defineComponent({
    components: { ReCaptchaSubmit },
    props: {
        dataId: { required: true, type: String },
        emailName: { required: true, type: String },
        firstName: { required: true, type: String },
        lastName: { required: true, type: String },
        formId: { default: 'subForm', type: String },
        captchaKey: { required: true, type: String },
        siteName: { required: true, type: String },
        label: { required: true, type: String }
    },
    data() {
        return {
            formData: {
                email: '',
                firstName: '',
                secondName: ''
            }
        };
    },

    mounted() {
        // add script to document
        const script = document.createElement('script');
        script.src = 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js';
        document.body.appendChild(script);
    },

    computed: {
        allData() {
            return (this.formData.email !== '' && this.formData.firstName !== '' && this.formData.secondName !== '');
        }
    }
});

</script>
