<template>
    <div class="custom-tabs__container" :class="{'custom-tabs__container--desktop': desktop}">
        <vit-dropdown :label="tabs.length > activeTab ? tabs[activeTab].title : ''" mobile-only v-if="!mobile">
            <dropdown-item
                v-for="(tab, i) in tabs"
                :key="tab.title"
                @click="selectTab(i)"
                :label="tab.title" />
        </vit-dropdown>
        <!-- nav tabs -->
        <div>
            <ul :ref="uuid+'-nav'" class="nav nav-tabs nav-justified" :class="navClass">
                <li
                    :ref="uuid+'-tabs'"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :id="tab.anchorId"
                    :class="['nav-item', 'tab', { 'tab--disabled': tab.disabled, 'tab--narrow': tab.narrow, 'tab--fixed': tab.fixedWidth, 'tab--content': tab.contentWidth }]">
                    <div
                        @click="selectTab(index)"
                        class="nav-link tab__content"
                        :class="{'active': activeTab === index, 'disabled': tab.disabled }">
                        <span class="tab__title">{{ tab.titlePrint }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <!-- content tabs -->
        <div class="tab-content custom-tabs__content" :class="contentClass">
            <slot />
        </div>
        <div v-if="sliding && draggable" class="custom-tabs__slide-controls">
            <button
                class="button button--borderless button--left"
                :class="{ 'button--hidden': activeTab === firstActive }"
                @click="slideLeft" />
            <button
                class="button button--borderless"
                :class="{ 'button--hidden': activeTab === lastActive }"
                @click="slideRight" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import DropdownItem from './VitDropdownItem.vue';
import VitDropdown from './VitDropdown.vue';
import BoundsMinMax = Draggable.BoundsMinMax;

export default defineComponent({
    components: { VitDropdown, DropdownItem },
    props: {
        uuid: { type: String, default: 'tab' },
        value: { type: Number, default: 0 },
        tabsLenght: { type: Number, default: 0 },
        desktop: { type: Boolean, default: false },
        editMode: { type: Boolean, default: false },
        mobile: { type: Boolean, default: false },
        sliding: { type: Boolean, default: false },
        contentClass: { type: String, default: '' }
    },
    data() {
        return {
            firstActive: -1,
            lastActive: -1,
            activeTab: 0,
            tabs: [],
            loaded: false,
            hash: false,
            draggable: null
        };
    },

    created() {
        this.activeTab = this.value;
        gsap.registerPlugin(Draggable);
        window.addEventListener('resize', this.initDraggable);
    },
    mounted() {
        // wait to create and update all tabs
        this.$nextTick(() => {
            if (this.editMode) {
                // select last selected tab
                this.activeTab = sessionStorage.getItem(this.uuid) ? parseInt(sessionStorage.getItem(this.uuid)) : 0;
                this.updateTabs();
            } else {
                this.updateTabs();
                if (this.sliding) {
                    this.initDraggable();
                }
                if (this.hash) {
                    this.$nextTick(() => {
                        this.$el.scrollIntoView({ behavior: 'smooth' });
                    });
                }
            }
            this.loaded = true;
        });
        window.addEventListener('hashchange', this.handleHashChange);
    },

    destroyed() {
        window.removeEventListener('hashchange', this.handleHashChange);
        window.removeEventListener('resize', this.initDraggable);
    },

    methods: {
        registerTab(tab) {
            this.tabs.push(tab);
            // if hash have element anchor id, open element tab
            if (tab.hasHashToAnchorID() || (this.loaded && this.tabs.length - 1 === this.activeTab)) {
                this.activeTab = this.tabs.length - 1;
                tab.isActive = true;
                this.hash = true;
            }
            // get first and last index not disabled
            if (!tab.disabled && this.firstActive === -1) {
                this.firstActive = this.tabs.length - 1;
                this.lastActive = this.tabs.length - 1;
            } else if (!tab.disabled) {
                this.lastActive = this.tabs.length - 1;
            }
        },
        // select Tab
        selectTab(selectedIndex) {
            if (!this.tabs[selectedIndex].disabled) {
                // eslint-disable-next-line no-unused-expressions
                this.tabs[this.activeTab] ?? (this.tabs[this.activeTab].isActive = false);
                this.activeTab = selectedIndex;
                // eslint-disable-next-line no-unused-expressions
                this.tabs[this.activeTab] ?? (this.tabs[this.activeTab].isActive = true);
            }
        },
        // update active prop on child tabs
        updateTabs() {
            this.tabs.forEach((t, i) => {
                t.isActive = this.activeTab === i;
            });
        },

        initDraggable() {
            // check if already initialised before
            if (this.draggable) {
                this.draggable.kill();
                this.draggable = null;
            }
            let tabsPerPage = 0;

            // check if have items
            if (!this.tabs.length || !(this.slideSlides && this.slideSlides[0])) {
                return;
            }

            // get button info
            const button = this.slideSlides[0];
            const buttonWidth = button.offsetWidth;
            const buttonStyle = getComputedStyle(button);
            const buttonXPadding = -parseFloat(buttonStyle.getPropertyValue('padding-left')) - parseFloat(buttonStyle.getPropertyValue('padding-right'));

            tabsPerPage = this.slideContainer.offsetWidth / buttonWidth;

            // no draggable needed if tabs fit on one page
            if (this.tabs.length <= tabsPerPage) {
                return;
            }
            this.draggable = new Draggable(this.slideContainer, {
                type: 'x',
                edgeResistance: 0.85,
                // eslint-disable-next-line no-unused-expressions
                bounds: { minX: ((tabsPerPage - this.tabs.length) * buttonWidth + buttonXPadding), maxX: 0 } as BoundsMinMax
            });
        },

        // update position
        updateSlideTransform() {
            if (!this.draggable || !(this.slideSlides && this.slideSlides[0])) {
                return;
            }
            const button = this.slideSlides[0];
            const buttonWidth = button.offsetWidth;

            gsap.to(this.slideContainer, { duration: 0.4, x: `-${Math.min(this.activeTab * buttonWidth, Math.abs(this.draggable.vars.bounds.minX))}px` });
        },

        slideLeft() {
            // go to prev not disabled
            for (let i = this.activeTab - 1; i >= 0; i--) {
                if (!this.tabs[i].disabled) {
                    this.activeTab = i;
                    this.updateSlideTransform();
                    break;
                }
            }
        },

        slideRight() {
            // go to next not disabled
            for (let i = this.activeTab + 1; i < this.tabs.length; i++) {
                if (!this.tabs[i].disabled) {
                    this.activeTab = i;
                    this.updateSlideTransform();
                    break;
                }
            }
        },
        handleHashChange() {
            this.tabs.forEach((tab, index) => {
                if (tab.hasHashToAnchorID()) {
                    this.activeTab = index;
                    tab.isActive = true;
                    this.$nextTick(() => {
                        this.$el.scrollIntoView();
                    });
                }
            });
        }
    },
    computed: {
        navClass() {
            return {
                'custom-tabs': true,
                'custom-tabs--mobile': this.mobile,
                'custom-tabs--sliding': this.sliding
            };
        },

        slideContainer(): Element {
            if (this.tabs) {
                // eslint-disable-next-line prefer-template
                return (this.$refs[this.uuid + '-nav'] as HTMLElement);
            }
            return null;
        },
        slideSlides(): Element {
            // eslint-disable-next-line prefer-template
            return this.$refs[this.uuid + '-tabs'] ? (this.$refs[this.uuid + '-tabs'] as HTMLElement) : null;
        },

        mergedContentClass() {
            if (this.contentClass.length) {
                return `custom-tabs__content ${this.contentClass}`;
            }
            return 'custom-tabs__content';
        }
    },
    watch: {
        // tab changed
        activeTab() {
            if (this.activeTab < 0) {
                this.activeTab = 0;
                return;
            }
            this.updateTabs();
            this.$emit('input', this.activeTab);
            this.updateSlideTransform();
            // save selected tab to session storage, so it can be restored after reload
            // (for edit mode only)
            if (this.editMode) {
                sessionStorage.setItem(this.uuid, `${this.activeTab}`);
            }
        }
    }
});

</script>
