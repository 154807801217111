import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "segregation-plan__content" }
const _hoisted_2 = {
  key: 0,
  class: "segregation-plan__result-error-outer"
}
const _hoisted_3 = { class: "segregation-plan__result-error" }
const _hoisted_4 = { class: "segregation-plan__result-error-inner" }
const _hoisted_5 = {
  key: 1,
  class: "segregation-plan__result-error-outer"
}
const _hoisted_6 = { class: "segregation-plan__result-error" }
const _hoisted_7 = { class: "segregation-plan__result-error-inner" }
const _hoisted_8 = { class: "segregation-plan__filter-date" }
const _hoisted_9 = {
  key: 3,
  class: "segregation-plan__result-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_segregation_plan_filter = _resolveComponent("segregation-plan-filter")!
  const _component_icon = _resolveComponent("icon")!
  const _component_segregation_plan_table = _resolveComponent("segregation-plan-table")!
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_segregation_plan_filter, {
        ref: "filter",
        "base-url": _ctx.wsBaseUrl,
        translations: _ctx.translations,
        onRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loading = true)),
        onIsloaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.firstLoad = false)),
        onSiteSelected: _ctx.onSiteSelected,
        onCommodities: _ctx.onCommoditiesLoaded,
        onCommoditiesPrices: _ctx.onPricesChanged
      }, null, 8, ["base-url", "translations", "onSiteSelected", "onCommodities", "onCommoditiesPrices"]),
      (!_ctx.siteSelected && _ctx.firstLoad)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translations.noSite), 1)
            ])
          ]))
        : (!_ctx.commodityPrices.length && !_ctx.loading && _ctx.firstLoad)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translations.noResults), 1)
              ])
            ]))
          : (_ctx.commodities.length > 0 && _ctx.commodityPrices.length > 0)
            ? (_openBlock(), _createBlock(_component_vit_tabs, {
                key: 2,
                "tabs-lenght": _ctx.commodities.length,
                mobile: "",
                sliding: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commodities, (commodity) => {
                    return (_openBlock(), _createBlock(_component_vit_tab, {
                      key: `table-${commodity.cName}`,
                      disabled: !commodity.available,
                      title: commodity.cName,
                      "fixed-width": "",
                      capitalize: "",
                      narrow: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translations.lastRefresh) + ": " + _toDisplayString(_ctx.refreshDate), 1),
                        _createVNode(_component_icon, {
                          class: _normalizeClass([{ 'segregation-plan__refresh-loading': _ctx.loading }, "segregation-plan__refresh"]),
                          name: "icn-refresh",
                          onClick: _ctx.refreshPrices
                        }, null, 8, ["class", "onClick"]),
                        (_ctx.pricesForCommodity(commodity.cId))
                          ? (_openBlock(), _createBlock(_component_segregation_plan_table, {
                              key: 0,
                              translations: _ctx.translations,
                              onExport: _ctx.exportXlsx,
                              "show-pricing": _ctx.showPricing,
                              onToggleShowPricing: _ctx.toggleShowPricing,
                              "show-opening-hours": _ctx.showOpeningHours,
                              onToggleOpeningHours: _ctx.toggleOpeningHours,
                              "show-e-p-pricing": _ctx.showEPPricing,
                              onToggleShowEPPricing: _ctx.toggleShowEPPricing,
                              "show-s-c-pricing": _ctx.showSCPricing,
                              onToggleShowSCPricing: _ctx.toggleShowSCPricing,
                              commodity: commodity,
                              "commodity-prices": _ctx.pricesForCommodity(commodity.cId),
                              "details-page-url": _ctx.detailsPageUrl,
                              onRemoveSite: _ctx.removeSite
                            }, null, 8, ["translations", "onExport", "show-pricing", "onToggleShowPricing", "show-opening-hours", "onToggleOpeningHours", "show-e-p-pricing", "onToggleShowEPPricing", "show-s-c-pricing", "onToggleShowSCPricing", "commodity", "commodity-prices", "details-page-url", "onRemoveSite"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["tabs-lenght"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_loading_spinner)
              ]))
    ])
  ]))
}