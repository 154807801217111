<template>
    <div class="horizontal-scroll-slider" :class="{ 'horizontal-scroll-slider--edit-mode': editMode }">
        <div class="horizontal-scroll-slider__buttons" ref="buttons">
            <div
                v-for="(slide, s) in slides"
                :key="`slideBtn#${s}`"
                @click="gotoSlide(s + 1)"
                class="horizontal-scroll-slider__button"
                :class="[{ 'horizontal-scroll-slider__button-active': s + 1 === index }, `horizontal-scroll-slider__button-${slide.icon}`]">
                <icon v-if="slide.icon" :name="slide.icon" class="horizontal-scroll-slider__button-icon" />
                <span class="horizontal-scroll-slider__button-title">{{ slide.title }}</span>
            </div>
        </div>
        <div
            class="horizontal-scroll-slider__wrapper horizontal-scroll-slider__main-wrapper"
            :style="{ transform: `translateX(-${progress}px)` }"
            ref="slider">
            <div class="horizontal-scroll-slider__intro" ref="intro">
                <div class="horizontal-scroll-slider__intro-title" v-html="$truncate(title)" />
                <div class="text horizontal-scroll-slider__intro-text" v-html="$truncate(text, 310)" />
            </div>
            <div class="horizontal-scroll-slider__slides" ref="slidesContainer">
                <svg
                    viewBox="0 0 1747 597"
                    preserveAspectRatio="xMinYMid"
                    ref="truckPathSVG"
                    class="horizontal-scroll-slider__path"
                    xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(0.000000, 80.000000)">
                        <path
                            d="M5.99910136,224 L5.99910136,26.34 C5.99910136,26.34 5.81,0.5 31.84,0.5 L328.16,0.5 C328.16,0.5 352.88,0.5 354,26.34 L354,66.34 C354,66.34 357.34,89.09 384.91,87.47 L415.16,87.47 C415.16,87.47 441,88.63 441,111.74 L441,409.66 C441,409.66 441.19,435.502913 466.5,435.502913 L762.92,435.502913 C762.92,435.502913 788.76,436.07 788.76,409.66 L788.76,372.26 C788.76,372.26 791.47,348.76 813.66,348.76 L850.16,348.76 C850.16,348.76 876,349.36 876,322.92 L876,26.34 C876,26.34 875.42,0.5 901.84,0.5 L1198.16,0.5 C1198.16,0.5 1224,1.18 1224,29.5 L1224,62.6 C1224,62.6 1224.86,88.98 1255.93,87.5 L1285.16,87.5 C1285.16,87.5 1308.88,87.18 1311,112.5 L1311,409.66 C1311,409.66 1311.72,435.502913 1336.84,435.502913 L1633.46,435.502913 C1633.46,435.502913 1659.28,434.5 1659,409.66 L1659,378.5 C1659,378.5 1658.1,353.01 1680.07,348.76 L1723.16,348.11 C1723.16,348.11 1746,346.89 1746,322.11 L1746,224"
                            id="truck-path"
                            ref="path"
                            class="horizontal-scroll-slider__truck-path"
                            stroke="#f0f"
                            fill="none" />
                        <g transform="translate(0.000000, 212.000000)" fill="#0AAA64" fill-rule="nonzero">
                            <rect
                                class="horizontal-scroll-slider__truck"
                                x="0"
                                y="0"
                                width="12"
                                height="12"
                                rx="3" />
                        </g>
                    </g>
                </svg>
                <icon name="icn-arrow" class="horizontal-scroll-slider__arrow" />
                <slot />
            </div>
        </div>
        <div class="slider__controls-wrapper">
            <div class="slider__controls horizontal-scroll-slider__controls">
                <icon name="icn-bracket" class="slider__controls-bracket" />
                <div
                    class="button button--borderless button--reverse"
                    :class="{ 'button--disabled': index === 0 }"
                    @click="prev" />
                <div class="slider__indicators">
                    <div
                        class="slider__indicator"
                        :class="{ 'slider__indicator--active': index === 0 }"
                        @click="gotoSlide(0)" />
                    <div
                        v-for="(slide, i) in slides"
                        :key="slide._uid"
                        class="slider__indicator"
                        :class="{ 'slider__indicator--active': index === i + 1 }"
                        @click="gotoSlide(i + 1)" />
                </div>
                <div
                    class="button button--borderless"
                    @click="next"
                    :class="{ 'button--disabled': index >= slides.length }" />
                <icon name="icn-bracket" class="slider__controls-bracket slider__controls-bracket-right" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Linear } from 'gsap/gsap-core';
import { Draggable } from 'gsap/Draggable';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { mapState } from 'pinia';
import { useMyStore } from '../store';
import Icon from './atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        title: { required: true, type: String },
        text: { required: true, type: String },
        editMode: { default: false, type: Boolean }
    },
    data() {
        return {
            slides: [],
            index: 0,
            scrollTrigger: null,
            progress: 0,
            maxScroll: 0,
            autoScroll: false,
            truckTween: null,
            draggable: null,
            draggingEl: null as Element,
            dragTween: null
        };
    },
    provide() {
        return {
            registerShapeSlide: this.register
        };
    },

    /**
     * Vue lifecycle hooks for setup and teardown
     */
    created() {
        gsap.registerPlugin(MotionPathPlugin);
        gsap.registerPlugin(ScrollToPlugin);
        gsap.registerPlugin(Draggable);
        gsap.registerPlugin(ScrollTrigger);
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
        window.addEventListener('DOMContentLoaded', this.recalculate());
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('DOMContentLoaded', this.recalculate());
    },

    methods: {
        /**
         * TRUCK TWEEN ANIMATION (green dot)
         */

        /**
         * create the truck animation
         */
        initTruckTween() {
            const pathEl = this.$refs.path as Element;
            const rawPath = MotionPathPlugin.getRawPath('#truck-path');
            // adjust the length of the path according to the number of slides (path is 4 slides long)
            pathEl.setAttribute('d', this.stretchPathLength(rawPath, this.slides.length / 4));
            this.truckTween = gsap.timeline({ paused: true });
            this.truckTween.to('.horizontal-scroll-slider__truck', 1, {
                ease: Linear.easeNone,
                motionPath: {
                    path: '#truck-path',
                    align: '#truck-path',
                    alignOrigin: [0.5, 0.5]
                }
            });
        },
        /**
         * updates the position of the truck (used on mobile only)
         */
        updateTruck() {
            this.truckTween.progress(Math.max(0, this.progress / this.maxScroll));
        },

        /**
         * kills the onDragEnd tween (when user start dragging again or navigations with buttons
         */
        killDragTween() {
            if (this.dragTween) {
                this.dragTween.kill();
            }
        },
        /**
         * shortens or expands the svg path by the given percentage
         * expanded paths are repeated on the x-Axis (shifted relatively)
         */
        stretchPathLength(rawPath: gsap.plugins.RawPath, factor: number): string {
            // first, repeat the path {Math.ceil(factor) - 1} times by appending it to itself
            // -1 because we already have 1 path
            let repeats = Math.ceil(factor) - 1;
            // later we slice the fractal part
            const slice = factor / (repeats + 1);
            // the original path we will append
            const path = rawPath[0];
            let next = path;
            while (repeats--) {
                // offset the original path in x direction by the endpoint of our total path
                const offset = next.slice(-2).shift() - path[0];
                next = path.map((x, i) => ((i % 2 === 0) ? x + offset : x));
                // append the shifted path to the total path
                rawPath.push(next);
            }
            // slice the fractal part of the factor
            rawPath = MotionPathPlugin.sliceRawPath(rawPath, 0, slice);
            // convert to 'd' string
            return MotionPathPlugin.rawPathToString(rawPath);
        },

        /**
         * SCROLL TRIGGER FOR DESKTOP
         */
        /**
         * create scrollmagic scene
         */
        initScrollTrigger() {
            this.scrollTrigger = gsap.to(this.$refs.slider, {
                animation: 'timeline',
                ease: 'none',
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'center center',
                    end: () => `+=${this.$refs.slider.clientWidth}`,
                    pin: true,
                    scrub: true,
                    onUpdate: self => {
                        this.progress = Math.ceil(this.maxScroll * self.progress);
                        if (this.truckTween) {
                            this.truckTween.progress(self.progress);
                        }
                        if (!this.autoScroll) {
                            this.index = Math.round(this.slides.length * self.progress);
                        }
                    }
                }
            });
        },

        /**
         * kill scroll at mobile
         */
        killScrollTrigger() {
            if (this.scrollTrigger) {
                this.scrollTrigger.scrollTrigger.kill();
                this.scrollTrigger = null;
            }
        },

        /**
         * DRAGGABLE FOR MOBILE
         */
        /**
         * create draggable slider
         */
        initDraggable() {
            this.draggingEl = document.createElement('div');
            this.draggable = new Draggable(this.draggingEl, {
                type: 'x',
                trigger: this.$refs.slider as Element,
                onDragStart: this.killDragTween,
                onDrag: this.updateProgress,
                onDragEnd: this.onDragEnd,
                bounds: { minY: 0, maxY: 0, minX: -this.maxScroll, maxX: 0 }
            });
        },

        /**
         * translate the draggable status to the actual slider
         * @param val
         */
        updateProgress() {
            const x = gsap.getProperty(this.draggingEl, 'x') as number;
            this.progress = -x;
            this.updateTruck();
        },

        /**
         * ease-snap to the nearest slide if draggable is released
         * update the draggable controller and the index accordingly
         */
        onDragEnd() {
            const snapTo = gsap.utils.snap(this.maxScroll / this.slides.length, gsap.getProperty(this.draggingEl, 'x') as number);
            this.index = Math.round(Math.abs(snapTo / (this.maxScroll / this.slides.length)));
            this.dragTween = gsap.to(this, {
                progress: -snapTo,
                duration: 0.2,
                onUpdate() {
                    gsap.set(this.draggingEl, { x: -this.progress });
                    this.updateTruck();
                },
                callbackScope: this
            });
        },

        /**
         * kill draggabble at desktop
         */
        killDraggable() {
            if (this.draggable && typeof this.draggable.kill === 'function') {
                this.draggable.kill();
                this.draggable = null;
            }
        },

        /**
         * LOGIC FUNCTIONS
         */
        /**
         * trigger recalculations on window resize
         */
        onResize() {
            this.recalculate(false);
        },

        recalculate(viewportChanged = false) {
            this.calcSizes();
            if (!this.isMobile && !this.editMode) {
                // teardown draggable
                this.killDraggable();
                this.$nextTick(() => {
                    if (this.scrollTrigger) {
                        // we already have a scrollTrigger => update it
                        this.scrollTrigger.scrollTrigger.vars.end = `+=${this.$refs.slider.clientWidth}`;
                        this.scrollTrigger.scrollTrigger.refresh();
                    } else if (viewportChanged || !this.scrollTrigger) {
                        this.initScrollTrigger();
                    }
                });
            } else if (!this.editMode) {
                // teardown scrollmagic
                this.killScrollTrigger();
                this.$nextTick(() => {
                    if (this.draggable) {
                        // we already have a draggable => update it
                        this.draggable.applyBounds({ minY: 0, maxY: 0, minX: -this.maxScroll, maxX: 0 });
                        // snap slider to the next legal position
                    } else if (viewportChanged || !this.draggable) {
                        // we came from desktop to mobile => setup draggable
                        this.initDraggable();
                    }
                    this.onDragEnd();
                });
            }
        },

        /**
         * calculates the widths of the intro, slider and a single slide
         * called on init and resize
         */
        calcSizes() {
            // eslint-disable-next-line dot-notation
            if (!this.isMobile) {
                this.maxScroll = (this.$refs.slider as Element).clientWidth - this.$el.clientWidth;
            } else {
                this.maxScroll = (this.$refs.slidesContainer as Element).clientWidth - (this.currentBreakpoint === 'xSmall' ? 30 : 0);
            }
        },

        /**
         * jump to a specific slide (called by arrow button handlers, indicators or category buttons)
         */
        gotoSlide(index) {
            this.index = index;
            if (!this.isMobile) { // desktop move scrollmagic
                if (!this.scrollTrigger) {
                    return;
                }
                const allSlideWidth = this.$refs.slider.clientWidth;
                const allItemsWidth = (this.$refs.slidesContainer as Element).clientWidth;
                const itemsWidth = allItemsWidth / this.slides.length;
                const beginningWidth = allSlideWidth - allItemsWidth;
                const slideRangeScroll = this.scrollTrigger.scrollTrigger.end - this.scrollTrigger.scrollTrigger.start;
                const itemPosition = beginningWidth + (itemsWidth * index);
                const itemPositionWithoutBeginning = itemPosition - beginningWidth;
                const scrollTo = ((slideRangeScroll * itemPositionWithoutBeginning) / (allSlideWidth - beginningWidth)) + this.scrollTrigger.scrollTrigger.start;

                gsap.to(window, {
                    scrollTo: {
                        y: scrollTo,
                        autoKill: false
                    },
                    duration: 1
                });
            } else { // mobile: move draggable
                this.killDragTween();
                this.dragTween = gsap.to(this, {
                    progress: (this.index * this.maxScroll) / this.slides.length,
                    duration: 0.2,
                    onUpdate: () => {
                        gsap.set(this.draggingEl, { x: -this.progress });
                        this.updateTruck();
                    }
                });
            }
        },

        /**
         * callbacks for arrow buttons
         */
        prev() {
            if (this.index > 0) {
                this.gotoSlide(this.index - 1);
            }
        },

        next() {
            if (this.index < this.slides.length) {
                this.gotoSlide(this.index + 1);
            }
        },

        /**
         * called by child components. triggers init when all children are registered
         * @param slide a ShapeSlide.vue component
         * @returns true if the registered slide is even, false if its odd
         */
        register(slide): boolean {
            this.slides.push(slide);
            if (this.slides.length === this.renderSlotAndCount && !this.editMode) {
                this.$nextTick(() => {
                    this.initTruckTween();
                });
            }
            return this.slides.length % 2 === 0;
        }
    },

    computed: {
        ...mapState(useMyStore, {
            isMobile: state => state.isMobile,
            currentBreakpoint: state => state.currentBreakpoint
        }),
        // count the number os childrens at slot to get number of slides
        renderSlotAndCount() {
            const slotContent = this.$slots.default();
            const elementsCount = slotContent.length;
            return elementsCount;
        }
    },

    watch: {
        isMobile() {
            this.recalculate(true);
        }
    }
});
</script>
