import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "tab__title" }
const _hoisted_4 = {
  key: 1,
  class: "custom-tabs__slide-controls"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-tabs__container", {'custom-tabs__container--desktop': _ctx.desktop}])
  }, [
    (!_ctx.mobile)
      ? (_openBlock(), _createBlock(_component_vit_dropdown, {
          key: 0,
          label: _ctx.tabs.length > _ctx.activeTab ? _ctx.tabs[_ctx.activeTab].title : '',
          "mobile-only": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: tab.title,
                onClick: ($event: any) => (_ctx.selectTab(i)),
                label: tab.title
              }, null, 8, ["onClick", "label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("ul", {
        ref: _ctx.uuid+'-nav',
        class: _normalizeClass(["nav nav-tabs nav-justified", _ctx.navClass])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            ref_for: true,
            ref: _ctx.uuid+'-tabs',
            key: index,
            id: tab.anchorId,
            class: _normalizeClass(['nav-item', 'tab', { 'tab--disabled': tab.disabled, 'tab--narrow': tab.narrow, 'tab--fixed': tab.fixedWidth, 'tab--content': tab.contentWidth }])
          }, [
            _createElementVNode("div", {
              onClick: ($event: any) => (_ctx.selectTab(index)),
              class: _normalizeClass(["nav-link tab__content", {'active': _ctx.activeTab === index, 'disabled': tab.disabled }])
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(tab.titlePrint), 1)
            ], 10, _hoisted_2)
          ], 10, _hoisted_1))
        }), 128))
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tab-content custom-tabs__content", _ctx.contentClass])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.sliding && _ctx.draggable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["button button--borderless button--left", { 'button--hidden': _ctx.activeTab === _ctx.firstActive }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.slideLeft && _ctx.slideLeft(...args)))
          }, null, 2),
          _createElementVNode("button", {
            class: _normalizeClass(["button button--borderless", { 'button--hidden': _ctx.activeTab === _ctx.lastActive }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.slideRight && _ctx.slideRight(...args)))
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}