<template>
    <div>
        <div class="location-grid-lgcol3">
            <div
                class="location-box location-grid-staff"
                v-for="(staff, index) in staffs"
                :key="staff.id"
                v-show="index < showNumber && staff.DisplayName">
                <div class="location-grid-staff-image">
                    <div class="location-box-image-box">
                        <img
                            :src="getImage(staff.StaffNetworkId)"
                            @error="removeImage"
                            class="location-box-image">
                    </div>
                </div>
                <div class="location-box-pb-30 location-grid-staff-info">
                    <div class="location-box-details">
                        {{ staff.DisplayName }}
                    </div>
                    <div class="location-box-text-small location-box-thin">
                        {{ staff.PositionTitle }}
                    </div>
                </div>
                <div class="location-box-m-auto location-box-lghidden">
                    <a
                        v-if="formatPhone(staff.BusinessPhone)"
                        class="button button--phone location-box-mr-15"
                        :href="'tel:'+formatPhone(staff.BusinessPhone)"
                        rel="noopener noreferrer">{{ translations.call }}</a>
                    <a class="button button--mail" :href="'mailto:'+staff.InternalEmail" rel="noopener noreferrer">{{ translations.email }}</a>
                </div>
                <div class="location-grid-staff-lglink">
                    <a
                        v-if="formatPhone(staff.BusinessPhone)"
                        class="location-box-pb-30 location-box-phone-staff"
                        :href="'tel:'+formatPhone(staff.BusinessPhone)"
                        rel="noopener noreferrer">Tel. {{ formatPhone(staff.BusinessPhone) }}</a>
                    <a class="button" :href="'mailto:'+staff.InternalEmail" rel="noopener noreferrer">{{ translations.sendMessage }}</a>
                </div>
            </div>
        </div>
        <div v-if="showNumber < staffs.length" class="location-box-m-auto location-box-pt-45">
            <div class="button button--down" @click="showNumber+=3">
                {{ translations.showMore }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        staffs: { required: true, type: Array as () => Array<Object> },
        translations: { required: true, type: Object as () => Record<string, string> }
    },
    data() {
        return {
            changeMobile: 992,
            isMobile: false,
            showNumber: null as Number
        };
    },

    created() {
        this.showNumber = window.innerWidth > this.changeMobile ? 6 : 3;
        window.addEventListener('resize', this.updateSize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateSize);
    },

    methods: {
        getImage(code) {
            if (code) {
                return `https://mygavilonresources.gavilon.com/images/staff/${code}.jpg`;
            }
            return '';
        },
        removeImage(e) {
            e.target.src = `${this.$resourcePath}/samples/person-placeholder-male.jpg`;
        },
        updateSize() {
            this.isMobile = window.innerWidth <= this.changeMobile;
        },

        formatPhone(phone) {
            if (!phone || phone <= 13) {
                return '';
            }
            return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 13)}`;
        }
    },

    watch: {
        isMobile() {
            if (this.showNumber === 3 && !this.isMobile) {
                this.showNumber = 6;
            } else if (this.showNumber === 6 && this.isMobile) {
                this.showNumber = 3;
            }
        }
    }
});

</script>
