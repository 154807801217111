/**
 * Fade-in directive
 *
 * Fades in a HTMlElement as it scrolls into view
 * Usage: <div v-fade-in:delay></div> where delay (in ms) is an optional parameter
 * Relies on css classes defined in fade.css
 *
 * @author Julian Frank (julian.frank@lemonize.de)
 */
// eslint-disable-next-line import/no-unresolved
import { DirectiveBinding } from 'vue';

function onFadeIn(entries: IntersectionObserverEntry[]) {
    const windowWidth = window.innerWidth;
    const ratio = windowWidth > 800 ? 0.5 : 0.35;

    Array.from(entries).forEach(entry => {
        const target = entry.target;

        if (entry.intersectionRatio > ratio) {
            target.classList.add('fade-in:visible');
        } else if (target.classList.contains('fade-out')) {
            target.classList.remove('fade-in:visible');
        }
    });
}

export default {
    mounted(element: HTMLElement, binding: DirectiveBinding) {
        element.classList.add(`${binding.arg ? `${binding.arg}:` : ''}fade-in`);
        if (binding.value && !isNaN(parseInt(binding.value))) {
            element.style.transitionDelay = `${binding.value}ms`;
        }
        const observer = new IntersectionObserver(onFadeIn, {
            root: null,
            rootMargin: '0px 0px',
            threshold: 0.5
        });
        observer.observe(element);
    }
};
