/* eslint-disable */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useMyStore } from './store';
import VueLoader from './VueLoader';
import bsBreakpoints from './lib/bs-breakpoints';

const app = createApp({});
const pinia = createPinia();

app.config.warnHandler = function (msg, vm, trace) {
    // Verify if is prodution
    if (process.env.NODE_ENV !== 'production') {
        // if not show warnings
        console.warn(`[Vue warn]: ${msg}${trace}`);
    }
};

app.use(pinia);
window.addEventListener('load', async () => {
    // set initial breakpoint in store
    bsBreakpoints.init();
    const breakpoint = bsBreakpoints.detectBreakpoint();
    useMyStore().setBreakpoint(breakpoint);
    // to listen the change of the breakpoint and add it to pinia
    bsBreakpoints.registerListener((breakpoint) => {
        useMyStore().setBreakpoint(breakpoint);
    });
});
// resize images and svgs from 0 to 100%
// the css class is changed directly since image can be added after page is loaded
window.addEventListener('DOMContentLoaded', () => {
    // Create a new style sheet if none available
    if (!document.styleSheets.length) {
        var style = document.createElement('style');
        document.head.appendChild(style);
    }

    // Add the new rule to the file
    document.styleSheets[0].insertRule(`.image__img { height: 100% !important; width: 100% !important; max-width: 100%; }`, document.styleSheets[0].cssRules.length);
});

// Init pinia before components
const store = useMyStore();
store.setBreakpoint(bsBreakpoints.detectBreakpoint());

/* Plugins */
app.use(VueLoader);

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true;

app.mount('#app');
