<template>
    <gav-locations-drag @openPopUp="onPopupOpen" :open-pop-up="popupOpen" :mobile-selected="isLocationSelected">
        <template #static>
            <!-- search on map only for mobile -->
            <div class="locations_map-search">
                <div class="locations_map-search-m">
                    <vit-dropdown
                        :label="locationSelectedId ? locationSelected.description : translations.select"
                        light
                        dark-menu
                        rounded>
                        <dropdown-item :prevent-close-dropdown="true">
                            <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                                <icon class="icon" name="icn-search" />
                                <input class="cash-pricing__filter-search" type="text" @input="onSearchLocation">
                            </div>
                        </dropdown-item>
                        <dropdown-item
                            v-for="location in filteredLocations"
                            :key="'searchmap'+location.id"
                            @click="selectLocation(location)">
                            <span class="locations_locations-search-checkmark" :class="{'checkmark-active': location.id === locationSelectedId}" />
                            <span class="locations_locations-search-option">{{ location.description }}</span>
                        </dropdown-item>
                    </vit-dropdown>
                </div>
            </div>
            <!-- map -->
            <google-map
                :api-key="apiKey"
                :markers="locations"
                :center="position"
                @map-center="position = $event"
                :zoom="zoom"
                @map-zoom="zoom = $event"
                @marker-click="onMarkerClick" />
        </template>
        <template #stickytop>
            <div v-if="!locationSelectedId" class="locations_locations-dot" />
            <div v-if="!locationSelectedId" class="locations_locations-title">
                {{ translations.discoverLocation }}
            </div>
            <!-- search -->
            <div class="locations_locations-search">
                <vit-dropdown
                    :label="locationSelectedId ? locationSelected.description : translations.select"
                    light
                    dark-menu
                    rounded>
                    <dropdown-item :prevent-close-dropdown="true">
                        <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                            <icon class="icon" name="icn-search" />
                            <input class="cash-pricing__filter-search" type="text" @input="onSearchLocation">
                        </div>
                    </dropdown-item>
                    <dropdown-item
                        v-for="location in filteredLocations"
                        :key="'searchlist'+location.id"
                        @click="selectLocation(location)">
                        <span class="locations_locations-search-checkmark" :class="{'checkmark-active': location.id === locationSelectedId}" />
                        <span class="locations_locations-search-option">{{ location.description }}</span>
                    </dropdown-item>
                </vit-dropdown>
            </div>
        </template>
        <template #mobileselected>
            <!-- selected location to mobile -->
            <div
                v-if="locationSelectedId && locationSelected"
                class="locations_location-info locations_location-info-selected locations_location-info-selected-mobile-show"
                @click="selectLocation(locationSelected)">
                <div>
                    <a @click.stop :href="determineLocationUrl(locationSelected.description)" class="locations_location-title">{{ locationSelected.description }}</a>
                    <div>
                        <div class="locations_location-details">
                            {{ locationSelected.info.address }}
                        </div>
                        <div class="locations_location-details">
                            {{ locationSelected.info.city }}, {{ locationSelected.info.stateProvince }}, {{ locationSelected.info.postalCode }}
                        </div>
                    </div>
                    <div
                        v-if="locationSelected.info.openHour && locationSelected.info.closeHour"
                        class="locations_location-open">
                        {{ locationSelected.info.openHour }} - {{ locationSelected.info.closeHour }}
                    </div>
                </div>
                <div :class="locationSelected.contact.name ? 'locations_location-box-active' : 'locations_location-box'">
                    <img :src="gavilon.getImage(locationSelected.contact.image)" @error="gavilon.removeImage" class="locations_location-image">
                    <div>
                        <div class="locations_location-name">
                            {{ locationSelected.contact.name }}
                        </div>
                        <div class="locations_location-details">
                            {{ locationSelected.contact.title }}
                        </div>
                    </div>
                    <div class="locations_location-box-contacts">
                        <div v-if="locationSelected.contact.phone" class="locations_location-details">
                            T: {{ locationSelected.contact.phone }}
                        </div>
                        <a
                            v-if="locationSelected.contact.email"
                            @click.stop
                            class="locations_location-message"
                            rel="noopener noreferrer"
                            :href="'mailto:'+locationSelected.contact.email">{{ translations.message }}</a>
                    </div>
                    <div class="locations_location-box-buttons">
                        <a
                            v-if="locationSelected.contact.phone"
                            @click.stop
                            class="button button--phone"
                            rel="noopener noreferrer"
                            :href="'tel:+'+locationSelected.contact.phone">
                            {{ translations.call }}
                        </a>
                        <a
                            v-if="locationSelected.contact.email"
                            @click.stop
                            class="button button--mail"
                            rel="noopener noreferrer"
                            :href="'mailto:'+locationSelected.contact.email">
                            {{ translations.email }}
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template #scroll>
            <!-- list of locations by distance -->
            <div
                v-for="(location, index) in locationsDistance"
                :key="'list'+location.id"
                :id="'list'+location.id"
                class="locations_location-info"
                :class="{ 'locations_location-info-selected' : location.id === locationSelectedId, 'locations_location-info-first': !index, 'locations_location-info-selected-mobile': locationSelectedId && locationSelected }"
                @click="selectLocation(location)">
                <div>
                    <a @click.stop :href="determineLocationUrl(location.description)" class="locations_location-title">{{ location.description }}</a>
                    <div>
                        <div class="locations_location-details">
                            {{ location.info.address }}
                        </div>
                        <div class="locations_location-details">
                            {{ location.info.city }}, {{ location.info.stateProvince }}, {{ location.info.postalCode }}
                        </div>
                    </div>
                    <div
                        v-if="location.info.openHour && location.info.closeHour"
                        class="locations_location-open">
                        {{ location.info.openHour }} - {{ location.info.closeHour }}
                    </div>
                </div>
                <div :class=" location.id === locationSelectedId && location.contact.name ? 'locations_location-box-active' : 'locations_location-box'">
                    <img :src="gavilon.getImage(location.contact.image)" @error="gavilon.removeImage" class="locations_location-image">
                    <div>
                        <div class="locations_location-name">
                            {{ location.contact.name }}
                        </div>
                        <div class="locations_location-details">
                            {{ location.contact.title }}
                        </div>
                    </div>
                    <div class="locations_location-box-contacts">
                        <div v-if="location.contact.phone" @click.stop class="locations_location-details">
                            T: {{ location.contact.phone }}
                        </div>
                        <a
                            v-if="location.contact.email"
                            @click.stop
                            class="locations_location-message"
                            :href="'mailto:'+location.contact.email"
                            rel="noopener noreferrer">{{ translations.message }}</a>
                    </div>
                    <div class="locations_location-box-buttons">
                        <a
                            v-if="location.contact.phone"
                            @click.stop
                            class="button button--phone"
                            rel="noopener noreferrer"
                            :href="'tel:+'+location.contact.phone">{{ translations.call }}</a>
                        <a
                            v-if="location.contact.email"
                            @click.stop
                            class="button button--mail"
                            rel="noopener noreferrer"
                            :href="'mailto:'+location.contact.email">
                            {{ translations.email }}
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </gav-locations-drag>
</template>

<script lang="ts">
import { toNumber } from 'lodash';
import { defineComponent } from 'vue';
import Gavilon from '../../lib/Gavilon';
import GoogleMap from '../base/GoogleMap.vue';
import DropdownItem from '../base/VitDropdownItem.vue';
import GavLocationsDrag from './GavLocationsDrag.vue';
import Icon from '../atoms/Icon.vue';
import VitDropdown from '../base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, GoogleMap, Icon, GavLocationsDrag, DropdownItem },
    props: {
        apiKey: { required: true, type: String },
        translations: { required: true, type: Object as () => Record<string, string> }
    },
    data() {
        return {
            // all locations alphabetic and by distance
            locations: [] as Array<{ [key: string]: any }>,
            locationsDistance: [] as Array<{ [key: string]: any }>,
            // selected location
            locationSelectedId: null as Number,
            locationSelected: {} as { [key: string]: string },

            // map variables
            positionCenter: {
                lat: 39.56960541548833,
                lng: -94.33486244996047
            },
            position: null,
            zoom: 4,
            // person/user/client location
            gotPersonLocation: false,
            personLocation: { lat: 0, lng: 0 } as { [key: string]: number },
            // to use gavilon functions in template
            gavilon: Gavilon,

            locationFilter: '',

            scrollNumber: -120,
            popupOpen: false
        };
    },

    async created() {
        // initialise map in center of US
        this.position = this.positionCenter;

        // get all locations
        this.locations = await Gavilon.getMapLocations();

        // get selected location in cookies
        this.getLocationSelected();

        // get user position
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getPosition);
        }

        // order locations
        this.locationsDistance = [...this.locationsOrder()];

        // calculate scroll margin
        window.addEventListener('resize', this.scrollNumberUpdate);
        this.scrollNumberUpdate();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.scrollNumberUpdate);
    },

    methods: {
        // get position of user
        getPosition(position) {
            this.personLocation.lat = position.coords.latitude;
            this.personLocation.lng = position.coords.longitude;
            this.gotPersonLocation = true;
            // select the closest location
            if (!this.locationSelectedId && this.locationsOrder.length > 0 && this.locationsOrder[0].distance < 5000) {
                this.locationSelected = this.locationsOrder[0];
                this.locationSelectedId = toNumber(this.locationSelected.id);
                this.position = this.locationSelected.location;
                this.zoom = 12;
            }
        },

        // get location url
        determineLocationUrl(name: string) {
            return encodeURI(`${window.location}/${name}`);
        },

        // location selected by id (map)
        onMarkerClick(id) {
            this.selectLocation(this.locations.find(location => location.id === id));
        },

        // location selected by list
        selectLocation(location: { [key: string]: string }) {
            if (this.locationSelectedId && this.locationSelected.iconURL) {
                // eslint-disable-next-line dot-notation
                this.locationSelected.iconURL = `${window['resourcePath']}img/marker-gav.png`;
            }
            if (this.locationSelectedId !== toNumber(location.id)) {
                this.locationSelectedId = toNumber(location.id);
                this.locationSelected = location;
                this.position = location.location;
                // eslint-disable-next-line dot-notation
                this.locationSelected.iconURL = `${window['resourcePath']}img/marker-gav-active.png`;
                this.zoom = 12;
            } else {
                this.locationSelectedId = null;
                this.locationSelected = null;
                this.position = this.positionCenter;
                this.zoom = 4;
            }
        },

        // get location selected from cookie
        getLocationSelected() {
            this.locationSelectedId = Gavilon.getLocationCookie();
            if (this.locationSelectedId) {
                this.locationSelected = this.locations.find(element => element.id === this.locationSelectedId);
                // eslint-disable-next-line dot-notation
                this.locationSelected.iconURL = `${window['resourcePath']}img/marker-gav-active.png`;
                this.position = this.locationSelected.location;
                this.zoom = 12;
            }
        },

        // put the closest location first and order by distance
        locationsOrder() {
            let compare = {};
            if (this.locationSelectedId && !this.gotPersonLocation) {
                compare = this.locationSelected.location;
            } else {
                compare = this.personLocation;
            }
            // [...this.locations] to not reorder the original one
            const orderLocations = [...this.locations];
            return orderLocations.sort((a, b) => {
                const fa = Gavilon.calcCrow(a.location, compare);
                a.distance = fa;
                const fb = Gavilon.calcCrow(b.location, compare);
                b.distance = fb;
                return fa - fb;
            });
        },

        // update on emit
        onPopupOpen(value) {
            this.popupOpen = value;
        },

        scrollNumberUpdate() {
            this.scrollNumber = 0 - document.getElementsByClassName('drag_location-box-left-start')[0].clientHeight;
        },

        onSearchLocation(event: InputEvent) {
            this.locationFilter = (event.target as HTMLInputElement).value.toLowerCase();
        }
    },

    computed: {
        filteredLocations() {
            return this.locations.filter(item => item.description.toLowerCase().includes(this.locationFilter));
        },

        isLocationSelected() {
            return this.locationSelectedId !== null && !isNaN(this.locationSelectedId);
        }
    },

    watch: {
        locationSelectedId() {
            if (this.locationSelectedId) {
                this.popupOpen = false;
                // scroll to element
                const elementInScrollableDiv = document.getElementById(`list${this.locationSelectedId}`);
                if (elementInScrollableDiv) {
                    const positionFromTopOfScrollableDiv = elementInScrollableDiv.offsetTop;
                    const scrollableDivElement = document.getElementById('locationselement');
                    scrollableDivElement.scrollTop = positionFromTopOfScrollableDiv + this.scrollNumber;
                    document.body.style.overflow = 'unset';
                }
                Gavilon.setLocationCookie(this.locationSelectedId.toString());
            } else {
                Gavilon.eraseLocationCookie();
            }
        }
    }
});

</script>
