<template>
    <div
        class="main-navigation"
        :class="{ 'main-navigation--light': light, 'main-navigation--fixed': fixed || mobileMenuOpen, 'main-navigation--fixed-out': fading }"
        @mouseleave="onBlur"
        @mouseenter="onEnter">
        <div class="main-navigation__position">
            <div class="main-navigation__wrapper">
                <div class="navbar main-navigation__navbar navbar-light navbar-expand-lg" aria-label="main-nav">
                    <div class="main-navigation__wrapper">
                        <div class="main-navigation__top">
                            <div class="main-navigation__top-left">
                                <a :href="`${logoLink}`" class="navbar-brand main-navigation__logo" aria-label="Home">
                                    <div class="main-navigation__logo-img" :style="logoStyle" />
                                </a>
                            </div>
                            <meta-navigation
                                @open-overlay-nav="onOverlayTriggered(true)"
                                :items="metaNav"
                                :lang-items="langNav"
                                :light="light" />
                            <div class="main-navigation__mobile-menu">
                                <!-- should this exist? is wasn't being showed -->
                                <button
                                    type="button"
                                    aria-label="Toggle navigation"
                                    class="navbar-toggler main-navigation__toggle collapsed"
                                    @click="toggleSearch">
                                    <icon
                                        :name="searchVisible ? 'icn-cross' : 'icn-search'"
                                        class="main-navigation__search-icon main-navigation__search-icon-green" />
                                </button>
                                <button
                                    type="button"
                                    aria-label="Toggle navigation"
                                    class="navbar-toggler main-navigation__toggle collapsed"
                                    @click="openMobileMenu">
                                    <div
                                        v-if="!activeItem"
                                        class="main-navigation__toggle-icon main-navigation__burger-icon" />
                                    <icon v-else name="icn-cross" class="main-navigation__toggle-icon" />
                                </button>
                            </div>
                        </div>
                        <div id="nav-collapse" class="main-navigation__main-items navbar-collapse collapse">
                            <ul class="navbar-nav main-navigation__navbar-nav">
                                <slot />
                                <button class="main-navigation__search" @click="toggleSearch" :aria-label="searchLabel">
                                    <icon
                                        :name="searchVisible ? 'cross' : 'icn-search'"
                                        class="main-navigation__search-icon" />
                                </button>
                            </ul>
                        </div>
                        <div v-if="welcomeTo && country" class="main-navigation__country-identifier">
                            <span class="main-navigation__country-identifier-site">{{ welcomeTo }}</span>
                            <span class="main-navigation__country-identifier-country">{{ country }}</span>
                        </div>
                    </div>
                </div>
                <transition name="main-navigation__slide-animation">
                    <main-navigation-submenu
                        v-if="activeItem"
                        :current-item="activeItem"
                        @nav="closeMobileMenu"
                        @submenu="onHover">
                        <meta-navigation
                            :items="metaNav"
                            :light="true"
                            class="meta-navigation--mobile-only"
                            :lang-items="langNav"
                            @open-overlay-nav="onOverlayTriggered(true)" />
                    </main-navigation-submenu>
                </transition>
            </div>
            <search-bar
                :visible="searchVisible"
                :results="searchResults.map(x => ({ id: x.id, title: x.label }))"
                container
                :placeholder="searchPlaceholder"
                @result-clicked="gotoResult"
                @search="search">
                <div class="search-bar__container search-bar__footer" v-if="(numPages > 1 && searchPage > 0) || showLoadMoreButton">
                    <div v-if="numPages > 1 && searchPage > 0" class="pagination">
                        <div
                            v-for="i in numPages"
                            :key="`page#${i}`"
                            class="pagination-item"
                            :class="{ 'pagination-item-active' : i === searchPage }"
                            @click="selectPage(i)">
                            {{ i }}
                        </div>
                    </div>
                    <button v-else-if="showLoadMoreButton" class="button" @click="loadMore">
                        {{ loadMoreLabel }}
                    </button>
                </div>
            </search-bar>
            <overlay-navigation
                v-if="overlayNavigationOpen"
                @close="onOverlayTriggered(false)"
                :items="overlayNavItems" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';
import { debounce } from 'lodash';
import axios from 'axios';
import { mapState } from 'pinia';
import { useMyStore } from '../../store';
import MainNavigationSubmenu from './MainNavigationSubmenu.vue';
import MetaNavigation from './MetaNavigation.vue';
import OverlayNavigation from './OverlayNavigation.vue';
import Icon from '../atoms/Icon.vue';
import SearchBar from '../base/SearchBar.vue';
import { SEARCH_ENDPOINT } from '../../Constants';
import Helper from '../../lib/Helper';

const MOBILE_NAV_BREADCRUMB = 'Home';

export default defineComponent({
    components: { Icon, MainNavigationSubmenu, MetaNavigation, OverlayNavigation, SearchBar },
    props: {
        metaNav: Array,
        langNav: Array,
        logoLink: String,
        loadMoreLabel: String,
        searchLabel: String,
        searchPlaceholder: String,
        logoUrlLight: String,
        logoUrlDark: String,
        welcomeTo: String,
        country: String,
        copyrightLabel: String,
        overlayNavItems: Array,
        light: { default: false, type: Boolean }
    },
    data() {
        return {
            items: [],
            submenuTimer: null,
            activeItem: null,
            scrollPos: 0,
            fixed: false,
            fading: false,
            fadingTimer: null,
            overlayNavigationOpen: false,
            searchVisible: false,
            searchResults: [],
            search: null,
            searchPage: 0,
            searchPageSize: 20,
            searchTotal: 0,
            searchValue: '',
            mobileMenuOpen: false
        };
    },

    /*
     * LIFECYCLE
     */
    created() {
        this.search = debounce(this.onSearch, 350);
        provide('register', this.register);
        document.addEventListener('scroll', this.onScroll);
    },

    mounted() {
        this.scrollPos = Helper.getScrollTop();
    },

    beforeDestroy() {
        document.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        /*
        * EVENT HANDLER
        */

        onScroll() {
            if (this.mobileMenuOpen || this.overlayNavigationOpen) {
                return;
            }
            if (this.scrollMagicActive) {
                if (this.fixed && !this.fading) {
                    this.fadeOut();
                }
                return;
            }
            const fixed = this.scrollPos > Helper.getScrollTop() && Helper.getScrollTop() > 0;
            if (this.fixed && !fixed && !this.fading) {
                this.fadeOut();
            } else if (fixed && !this.fading) {
                clearTimeout(this.fadingTimer);
                this.fixed = true;
                this.fading = false;
            }
            this.scrollPos = Helper.getScrollTop();
        },

        onHover(item) {
            if (item.items && item.items.length) {
                this.activeItem = item;
                this.searchVisible = false;
            } else {
                this.activeItem = null;
            }
        },

        onEnter() {
            clearTimeout(this.submenuTimer);
        },

        onBlur() {
            clearTimeout(this.submenuTimer);
            this.submenuTimer = setTimeout(() => {
                if (!this.overlayNavigationOpen) {
                    this.activeItem = null;
                }
            }, 500);
        },

        openMobileMenu() {
            if (!this.activeItem) {
                this.activeItem = {
                    title: MOBILE_NAV_BREADCRUMB,
                    items: this.items,
                    link: `${this.logoLink}`
                };
                this.searchVisible = false;
            } else {
                this.activeItem = null;
            }
            this.mobileMenuOpen = this.activeItem !== null;
        },

        closeMobileMenu() {
            if (this.mobileMenuOpen) {
                this.activeItem = null;
                this.mobileMenuOpen = false;
            }
        },

        onOverlayTriggered(isOpen) {
            this.overlayNavigationOpen = isOpen;
            if (this.showMetaNav) {
                this.activeItem = null;
            }
        },

        selectPage(page) {
            this.searchPage = page;
            this.onSearch(this.searchValue);
        },

        loadMore() {
            this.searchPage++;
            this.onSearch(this.searchValue);
        },

        async onSearch(searchValue) {
            this.searchValue = searchValue;
            this.searchResults = [];
            if (searchValue.length >= 3) {
                const params = {
                    query: searchValue,
                    page: this.searchPage
                };
                const queryStr = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
                const response = await axios.get(`${this.$contextPath}${SEARCH_ENDPOINT}/${this.$siteName}/${this.$lang}?${queryStr}`);
                this.searchPage = response.data.page;
                if (this.searchPage === 0) {
                    this.searchResults = response.data.results;
                } else {
                    this.searchResults = this.searchResults.concat(response.data.results);
                }
                this.searchTotal = response.data.total;
            } else {
                this.searchTotal = 0;
                this.searchPage = 0;
            }
        },

        gotoResult(id) {
            const result = this.searchResults.find(x => x.id === id);
            if (result.link) {
                location.href = result.link;
            }
        },

        /**
         * METHODS
         */

        // called by child navigationItems
        register(item) {
            item.$el.addEventListener('mouseover', () => { this.onHover(item); });
            this.items.push(item);
        },

        toggleSearch() {
            this.searchVisible = !this.searchVisible;
            if (this.searchVisible) {
                this.activeItem = null;
            }
        },

        fadeOut() {
            clearTimeout(this.fadingTimer);
            this.fadingTimer = setTimeout(() => {
                this.fading = false;
            }, 300);
            this.fading = Helper.getScrollTop() > 0;
            this.fixed = false;
        }
    },

    computed: {
        ...mapState(useMyStore, {
            currentBreakpoint: state => state.currentBreakpoint,
            scrollMagicActive: state => state.scrollMagicActive
        }),
        showMetaNav() {
            return this.currentBreakpoint === 'large' || this.currentBreakpoint === 'xLarge';
        },

        showLoadMoreButton() {
            if (this.searchPage === 0) {
                return this.searchTotal > 5;
            }
            return this.searchTotal > 5 + this.searchPage * this.searchPageSize;
        },

        numPages() {
            return Math.ceil(this.searchTotal / 20);
        },

        logoStyle() {
            const styles = {};
            if (this.logoUrlLight && this.light) {
                styles['background-image'] = `url(${this.logoUrlLight})`;
            }
            if (this.logoUrlDark && !this.light) {
                styles['background-image'] = `url(${this.logoUrlDark})`;
            }
            return styles;
        }
    }
});
</script>
